import React from 'react';
import { COLORS } from '../constants/style';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Section = styled.section`
  background: ${COLORS.brightGold};
  padding: 1.5%;

  .inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /* flex-direction: column; */
    justify-content: center;
    border: 1px solid ${COLORS.textWhite};
    height: 80%;
    width: 100%;
    padding: 4%;
    align-items: center;
    h4 {
      margin: 5px 0;
    }

    a.cta {
      margin: 5px 2em;
      border-radius: 0;
      border: solid 1px ${COLORS.textMain};

      &:hover {
        background: ${COLORS.textMain};
        color: ${COLORS.brightGold};
      }
    }
  }
`;

export default function EnrolCTA() {
  return (
    <Section>
      <div className="inner">
        <h4>Read our Enrolment Process</h4>
        <Link to="/classes#enrol" className="cta btn btn-outline-default">
          LEARN MORE
        </Link>
      </div>
    </Section>
  );
}
