import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import SEO from '../components/SEO';
import LinedTitle from '../components/LinedTitle';
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import { FONTS, COLORS } from '../constants/style';
import EnrolCTA from '../containers/EnrolCTA';

const PageWrapper = styled.article`
  padding-top: 80px;

  .contact-container {
    width: 90%;
    max-width: 900px;
    margin: 3em auto;

    .social {
      margin-bottom: 1.5em;
      p {
        margin-left: 3px;
        margin-bottom: 0.5em;
      }

      a {
        color: ${COLORS.darkGold};
      }

      img {
        height: 30px;
        margin-right: 10px;

        &:hover {
          filter: grayscale(1);
        }
      }

      img.qr {
        width: 200px;
        height: 200px;
        border: solid 1px ${COLORS.brightGold};
        display: block;

        &.admin {
          width: 180px;
          height: 180px;
        }
      }

      @media (max-width: 767px) {
        text-align: center;
        img.qr {
          margin: auto;
        }
      }
    }

    label {
      font-family: ${FONTS.secondary};
      font-weight: 900;
    }

    .my-btn {
      background: ${COLORS.darkGold};
      border: none;
      @media (max-width: 576px) {
        width: 100%;
      }
    }
  }
`;

export default function Contact({ data }) {
  const { seoMetaTags } = data.page;
  const { facebookLink, youtubeLink, instagramLink } = data.contactInfo;

  return (
    <PageWrapper>
      <SEO meta={seoMetaTags} />
      <div className="contact-container">
        <LinedTitle wing>Get in touch with us</LinedTitle>
        <p className="mb-5">
          Contact us to enquire about our range of personalised and
          differentiated VCE / IB educational products and services.
        </p>
        <div className="row">
          <div className="col-md-6 pb-5 order-2 order-md-1">
            <div className="social text-center text-md-left">
              <h5 className="font-weight-bold mb-3">Follow us on:</h5>
              <p className="">Wechat Official Account</p>
              <img
                className="qr"
                src="/img/wechat-qr.jpg"
                alt="WeChat QR Code"
              />
            </div>
            <div className="social text-center text-md-left">
              <p>Instagram</p>
              <a rel="noopener noreferrer" target="_blank" href={instagramLink}>
                <img src="/img/socials/instagram.svg" alt="instagram" />
                edcellent_edu
              </a>
            </div>
            <div className="social text-center text-md-left">
              <p>YouTube</p>
              <a rel="noopener noreferrer" target="_blank" href={youtubeLink}>
                <img src="/img/socials/youtube.svg" alt="youtube" />
                Edcellent Education
              </a>
            </div>
            <div className="social text-center text-md-left">
              <p>Facebook</p>
              <a rel="noopener noreferrer" target="_blank" href={facebookLink}>
                <img src="/img/socials/facebook.svg" alt="facebook" />
                Edcellent Education
              </a>
            </div>
            <br />
            <div className="social text-center text-md-left">
              <h5 className="font-weight-bold mb-3">
                Reach us on Wechat Admin:
              </h5>
              <div className="d-inline-block mr-2">
                <p className="">Box Hill Campus</p>
                <img
                  className="qr admin"
                  src="/img/qr/boxhill.jpg"
                  alt="WeChat QR Code"
                />
              </div>
              <div className="d-inline-block mr-2">
                <p className="">City Campus</p>
                <img
                  className="qr admin"
                  src="/img/qr/cbd.png"
                  alt="WeChat QR Code"
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 pb-5 order-1">
            <Form
              name="Contact Form"
              method="POST"
              data-netlify="true"
              action="/thank-you"
            >
              <input type="hidden" name="form-name" value="Contact Form" />

              <FormGroup>
                <Label>Your Name</Label>
                <Input type="text" name="name" />
              </FormGroup>

              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label>Email</Label>
                    <Input type="email" name="email" />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>Year Level</Label>
                    <Input type="text" name="year-level" />
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup>
                <Label>Your Goal</Label>
                <Input rows={7} type="textarea" name="your-goal" />
              </FormGroup>

              <FormGroup>
                <Label>Your Concern</Label>
                <Input rows={9} type="textarea" name="your-concern" />
              </FormGroup>

              <input
                type="submit"
                value="Send Message"
                className="btn btn-warning my-btn"
              />
            </Form>
          </div>
        </div>
      </div>
      <EnrolCTA />
    </PageWrapper>
  );
}

export const query = graphql`
  {
    page: datoCmsHomePage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }

    contactInfo: datoCmsContactInfo {
      facebookLink
      youtubeLink
      instagramLink
    }
  }
`;
